import React, { useEffect, useRef, useState } from 'react';

import FB from '../assets/icons/social/FB.svg';
import IG from '../assets/icons/social/IG.svg';
import styled from 'styled-components';
import { SocialLinks } from 'utils/externalLinks';

const SocialRow = () => (
  <SocialLinkContainer>
    <SocialLinkItem>
      <SocialLinkLink href={SocialLinks.Facebook}>
        <FBicon />
      </SocialLinkLink>
    </SocialLinkItem>

    <SocialLinkItem>
      <SocialLinkLink href={SocialLinks.Instagram}>
        <IGicon />
      </SocialLinkLink>
    </SocialLinkItem>
  </SocialLinkContainer>
);

export default SocialRow;

const FBicon = styled(FB)`
  margin-left: 0;
`;

const IGicon = styled(IG)`
  margin-left: 0;
`;

const SocialLinkLink = styled.a`
  cursor: pointer;
`;

const SocialLinkItem = styled.li``;

const SocialLinkContainer = styled.ul`
  list-style: none;
  display: flex;
  gap: 1.5rem;
`;
